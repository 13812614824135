<template>
  <div class='verify-photo-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='verify-photo-title'>Let's verify your photo</div>
            <div class='verify-photo-stepper'>Hello {{ clientName }}!</div>

            <div class='verify-photo-sub-text'>
              Your photo should look like a passport sized photo
            </div>
            <div class='verify-uploaded-photo-text'>Uploaded Photo</div>
            <div class='verify-photo-wrapper'>
              <div class='verify-photo-uploaded-image'>
                <img
                  :src='verifyPhoto'
                  alt='Verify Photo'
                  class='verify-photo-uploaded-image-wrapper'
                />
              </div>
            </div>
            <div class='verify-photo-btn-wrapper'>
              <a-button type='primary' @click='onConfirm' :loading="loading">Confirm</a-button>
            </div>
            <div class='verify-photo-btn-wrapper'>
              <a-button @click='retakePhoto'>Retake</a-button>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import STAGES from '../../../constants/stages';
import services from '../../../services/apis';

export default {
  components: {},
  setup() {
    const store = useStore();
    const loading = ref(false);
    const clientName = computed(
      () => store.getters['onboardingStore/getClientName'],
    ).value;
    const verifyPhoto = computed(
      () => store.getters['onboardingStore/getVerifyPhoto'],
    ).value;
    onMounted(() => {
      window.scrollTo(0, 0);
      const currentStage = computed(
        () => store.getters['onboardingStore/getCurrentStage'],
      ).value;
      if (currentStage !== STAGES.STAGE_TYPE.SELFIE) {
        services
          .getStages()
          .then((response) => {
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          })
          .catch(() => {
          });
      }
    });

    const router = useRouter();
    const retakePhoto = () => {
      loading.value = true;
      router.push('/onboarding/upload/selfie');
    };
    const onConfirm = () => {
      // const data = {
      //   image: 'http://example.com',
      // };
      loading.value = true;
      services
        .selfieVerification()
        .then((response) => {
          loading.value = false;
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
          loading.value = false;
        });
    };
    return {
      retakePhoto,
      onConfirm,
      verifyPhoto,
      clientName,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/VerifyPhoto.scss';
</style>
